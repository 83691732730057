<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <!-- {{ $i18n.locale }}
      {{ $t('hello') }} -->
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import uz from '../../../../../libs/i18n/locales/uz.json'
import ru from '../../../../../libs/i18n/locales/ru.json'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      const messages = locale === 'uz' ? uz : ru
      localize(locale, messages)
    },
  },
  setup() {
    const locales = [
      {
        locale: 'uz',
        img: require('@/assets/images/flags/uz.png'),
        name: 'O\'zbekcha',
      },
      {
        locale: 'ru',
        img: require('@/assets/images/flags/ru.png'),
        name: 'Русский',
      },
    ]
    return {
      locales,
    }
  },
}
</script>

<style>

</style>
